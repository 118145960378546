<template>
  <div class="page-container">
    <page-header v-if="showHeader"></page-header>
    <page-table ref="table" show-paginate :get-data="orderPage" :fields="fields" :search-model="searchModel"
                show-serial-no :item-actions="[...pActions, ...actions]" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="" prop="state">
          <el-select v-model="searchModel.state" clearable placeholder="踏勘状态">
            <el-option v-for="(label, value) in stateMap"
                       :key="value"
                       :label="label"
                       :value="value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="state" style="width: 220px;">
          <el-date-picker style="width: 220px;"
                          v-model="searchModel.tkStartDateSearch"
                          type="daterange"
                          range-separator="-"
                          start-placeholder="踏勘开始日期"
                          end-placeholder="踏勘结束日期"
                          value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="" prop="webUserSearch" style="width: 150px">
          <el-input v-model="searchModel.webUserSearch" style="width: 150px"
                    clearable placeholder="踏勘人员电话"></el-input>
        </el-form-item>
        <el-form-item  label="" prop="webFarmerProvinceSearch" style="width: 90px">
          <el-input v-model="searchModel.webFarmerProvinceSearch" placeholder="所在省" style="width: 90px;"></el-input>
        </el-form-item>
        <el-form-item label="" prop="webFarmerCitySearch" style="width: 90px">
          <el-input v-model="searchModel.webFarmerCitySearch" placeholder="市区"  style="width: 90px;"></el-input>
        </el-form-item>
        <el-form-item  label="" prop="webFarmerAreaSearch" style="width: 90px">
          <el-input v-model="searchModel.webFarmerAreaSearch" placeholder="县/区" style="width: 90px"></el-input>
        </el-form-item>
        <el-form-item  label="" prop="webFarmerAddressSearch" style="width: 120px">
          <el-input v-model="searchModel.webFarmerAddressSearch" clearable placeholder="详细地址" style="width:120px"></el-input>
        </el-form-item>
      </template>
      <template slot="page-actions"></template>
    </page-table>
    <el-dialog title="派单" :visible.sync="transfer_dialog_visible" append-to-body
               :close-on-click-modal="false" width="35%" custom-class="min-w-750">
      <el-form :model="transferForm" :rules="transferRules" label-width="120px" ref="transferForm"
               v-loading="transfer_form_loading">
        <el-form-item label="工单编号">
          {{ transferForm.id }}
        </el-form-item>
        <el-form-item label="业务员" prop="userId">
          <el-select
              v-model="transferForm.userId"
              filterable
              remote
              clearable
              reserve-keyword
              placeholder="请输入开发商名称"
              :remote-method="userIdRemoteMethod"
              :loading="searech_user_id_loading">
            <el-option
                v-for="item in user_options"
                :key="item.id"
                :label="(item.developers ? item.developers.name : '') + ' ' + item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="transfer_dialog_visible = false">取 消</el-button>
          <el-button @click="transferSubmit('transferForm')" type="primary">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog title="工单详情" :visible.sync="info_dialog_visible" append-to-body v-if="curInfo"
               :close-on-click-modal="false" width="35%" custom-class="min-w-750">
      <div style="display: flex;flex-wrap: wrap;line-height: 40px;">
        <div style="width: 50%;">工单编号：{{curInfo.id}}</div>
        <div style="width: 50%;">工单状态：{{stateMap[curInfo.state]}}</div>
        <div style="width:100%;">经销商名称：{{curInfo.user && curInfo.user.distributor ? curInfo.user.distributor.name :
            '-'}}
        </div>
        <div style="width: 50%;">踏勘人员姓名：{{curInfo.user?curInfo.user.name : '-'}}</div>
        <div style="width: 50%;">手机号：{{curInfo.user?curInfo.user.phone: '-'}}</div>
        <div style="width: 50%;">用户名称：{{curInfo.farmer?curInfo.farmer.name : '-'}}</div>
        <div style="width: 50%;">用户电话：{{curInfo.farmer? curInfo.farmer.phone: '-'}}</div>
        <div style="width: 100%;" v-if="curInfo.farmer">屋顶地址：{{curInfo.farmer.province || ''}}{{curInfo.farmer.city ||
        ''}}{{curInfo.farmer.area || ''}}{{curInfo.farmer.address || ''}}
        </div>
        <div style="width: 50%;">工单类型：踏勘工单</div>
        <div style="width: 50%;" v-if="curInfo.createUser">创建人：{{curInfo.createUser.name || ''}}</div>
        <div style="width: 50%;">创建时间：{{curInfo.time || ''}}</div>
      </div>
    </el-dialog>
    <dispatch-order ref="dispatchOrder"
                    :working-salesman="userSalesmanList"
                    @confirmDispatchOrder="confirmDispatchOrder"></dispatch-order>
    <dispatch-order ref="transferOrder"
                    title="转单"
                    label="转单人"
                    :working-salesman="userSalesmanList"
                    @confirmDispatchOrder="confirmTransferOrder"></dispatch-order>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {orderPage, salesmanPage, orderTransfer} from "@/api/common"
import {getTaskState} from "@/utils/helper";
import {TECHNICAL_SURVEY_STATUS} from "@/utils/config";
import {
  userSalesman,
  wordOrderCheck,
  wordOrderClose, wordOrderExamine,
  wordOrderOpen,
  wordOrderTransfer
} from "@/api/apis";
import DispatchOrder from "@/pageInteraction/DispatchOrder";
import {examineMsg} from "@/pageInteraction/examine/ExamineMsg";

export default {
  name: 'Survey',
  props: {
    showHeader: {
      type: Boolean,
      default(){
        return true
      }
    },
    orderPage: {
      type: Function,
      default: orderPage
    },
    pActions: {
      type: Array,
      default(){
        return []
      }
    }
  },
  data() {
    return {
      searchModel: {
        type: '',
        webFarmerSearch: '',
        webUserSearch: '',
        webCreateUserSearch: '',
        // 省
        webFarmerProvinceSearch: '',
        // 市
        webFarmerCitySearch: '',
        // 区域
        webFarmerAreaSearch: '',
        // 地址
        webFarmerAddressSearch: '',
        // 时间
        tkStartDateSearch: '',
      },
      value1: '',
      fields: [
        {
          label: '姓名',
          width: 110,
          render({farmer}, h) {
            return h('div', farmer ? farmer.name : '')
          }
        },
        {
          label: '电话号码',
          render({farmer}, h) {
            return h('div', farmer ? farmer.phone : '')
          }
        },
        {
          label: '所在地区',
          render: ({ farmer }, h) => {
            const { province, city, area } = farmer || {}
            return h('div', [province, city, area].join(''))
          }
        },
        {
          label: '详细地址',
          render: ({ farmer }, h) => {
            const { address } = farmer || {}
            return h('div', address)
          }
        },
        {
          label: '所属公司',
          render({ createUser: user }, h) {
            const name = user?.distributor?.name || '-'
            return h('div', name)
          }
        },
        {
          label: '踏勘人员',
          width: 110,
          render({user}, h) {
            return h('div', user ? user.name : '')
          }
        },
        { label: '踏勘时间', key: 'tkDate', width: 180, align: 'center' },
        {
          label: '状态',
          width:100,
          render: ({ state }, h) => {
            return h('div', getTaskState(state))
          }
        },
      ],
      actions: [
        { action: 'info', label: '查看', type: 'primary', permission: 'tk:see' },
        // {
        //   action: 'dispatch',
        //   label: '派单',
        //   type: 'primary',
        // },

        {
          action: 'examine',
          label: '审核',
          type: 'primary',
          permission: 'tk:examine',
          showAction: item => {
            return item.state === 'EXAMINE'
          }
        },

      ],
      stateMap: TECHNICAL_SURVEY_STATUS,
      //
      transfer_form_loading: false,
      transferForm: {
        id: '',
        userId: '',
      },
      transferRules: {
        userId: [
          {required: true, message: '请选择业务员', trigger: 'blur'},
        ],
      },
      transfer_dialog_visible: false,
      searech_user_id_loading: false,
      user_options: [],
      info_dialog_visible: false,
      curInfo: null,

      // -
      userSalesmanList: [],

    }
  },
  components: {
    DispatchOrder,
    PageHeader,
    PageTable
  },
  created() {

  },
  mounted() {
    userSalesman().then(res => {
      this.userSalesmanList = res.records
    })
  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    // --------------
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    /**
     * 审核
     * @param item
     */
    examineAction(item){
      examineMsg(info => {
        const params = {
          workOrderId: item.id,
          msg: info.msg,
          state: info.state,
        }

        const loading = this.$loading({ fullscreen: true })
        wordOrderExamine(item.id, params).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$refs.table && this.$refs.table.loadData()
          }, 500)
        }).finally(() => {
          loading.close()
        })

      })
    },
    /**
     * 派单
     * @param item
     */
    dispatchAction(item){
      this.currItem = item
      this.$nextTick(() => {
        this.$refs.dispatchOrder.open()
      })
    },
    /**
     * 转单
     * @param item
     */
    transferAction(item){
      this.currItem = item
      this.$nextTick(() => {
        this.$refs.transferOrder.open()
      })
    },
    /**
     * 封单
     * @param item
     */
    sealAction(item){
      this.$confirm('确认封单吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({ fullscreen: true })
        wordOrderClose(item.id).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$refs.table && this.$refs.table.loadData()
          }, 500)
        }).finally(() => {
          loading.close()
        })
      })
    },
    /**
     * 解封
     * @param item
     */
    unblockAction(item){
      this.$confirm('确认解封吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({ fullscreen: true })
        wordOrderOpen(item.id).then(() => {
          this.$message.success('提交成功')
          setTimeout(() => {
            this.$refs.table && this.$refs.table.loadData()
          }, 500)
        }).finally(() => {
          loading.close()
        })
      })
    },
    /**
     * 确认派单
     * @param dispatchOrderUser
     */
    confirmDispatchOrder(dispatchOrderUser){
      const params = {
        userId: dispatchOrderUser,
        farmerId: this.currItem.farmer.id,
      }

      const loading = this.$loading({ fullscreen: true })
      wordOrderCheck(params).then(() => {
        this.$message.success('提交成功')
        setTimeout(() => {
          this.$refs.table && this.$refs.table.loadData()
        }, 500)
      }).finally(() => {
        loading.close()
      })
    },
    /**
     * 确认转单
     */
    confirmTransferOrder(dispatchOrderUser){
      const loading = this.$loading({ fullscreen: true })
      wordOrderTransfer(this.currItem.id, dispatchOrderUser).then(() => {
        this.$message.success('提交成功')
        setTimeout(() => {
          this.$refs.table && this.$refs.table.loadData()
        }, 500)
      }).finally(() => {
        loading.close()
      })
    },

    userIdRemoteMethod(query) {
      if (query == '') return
      let that = this
      that.searech_user_id_loading = true
      salesmanPage({developerName: query}).then(res => {
        that.user_options = res.records
        that.searech_user_id_loading = false
      }).catch(() => {
        that.user_options = []
        that.searech_user_id_loading = false
      })
    },
    transferSubmit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.transfer_form_loading = true
        orderTransfer(that.transferForm.id, that.transferForm.userId).then(() => {
          that.transfer_form_loading = false
          that.$message.success('提交成功')
          that.transfer_dialog_visible = false
          that.$refs.table.loadData()
        }).catch(() => {
          that.transfer_form_loading = false
        })
      })
    },
    infoAction(item) {
      // let that = this
      // that.curInfo = item
      // that.info_dialog_visible = true

      // --
      this.$router.push('/admin/survey/info/' + item.id)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
