import Vue from 'vue'
import ExamineMsg from './ExamineMsg.vue'

const ToastConstructor = Vue.extend(ExamineMsg)
const instance = new ToastConstructor().$mount()
document.body.appendChild(instance.$el)


/**
 * 审核
 * @param handler
 */
export const examineMsg = handler => {
  instance.showExaminePrompt(handler)
}

export const examineNotMsg = handler => {
  instance.showExaminePromptNotRemark(handler)
}