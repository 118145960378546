<template>
  <div>
    <div class="to-examine-container">
      <el-dialog title="审核信息"
                 :visible.sync="examineInfoShow"
                 width="30%">
        <div>
          <el-form :model="examineInfo"
                   :rules="examineInfoRules"
                   ref="examineInfoRef">
            <el-form-item label="是否通过" prop="passed">
              <el-radio v-model="examineInfo.passed" :label="true">通过</el-radio>
              <el-radio v-model="examineInfo.passed" :label="false">拒绝</el-radio>
            </el-form-item>
            <el-form-item v-if="showRemarks" label="备注" prop="msg">
              <el-input v-model="examineInfo.msg" placeholder="备注"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="mini" @click="submitExamine">提交</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExamineMsg",
  data(){
    return {
      examineInfoShow: false,
      examineInfo: {
        passed: true,
        msg: '',
      },
      examineInfoRules: {

      },
      //
      showRemarks: true,
    }
  },
  methods: {
    /**
     * 展开审核
     */
    showExaminePrompt(handler){
      this.handler = handler
      this.showRemarks = true
      this.examineInfoShow = true
    },
    /**
     * 展开审核
     */
    showExaminePromptNotRemark(handler){
      this.handler = handler
      this.showRemarks = false
      this.examineInfoShow = true
    },
    /**
     * 提交审核按钮
     */
    submitExamine(){
      const params = this.examineInfo
      params.state = this.examineInfo.passed ? 'SUCCESS' : 'FAIL'
      this.examineInfoShow = false
      this.handler && this.handler(params)
    }
  }
}
</script>

<style scoped>

</style>